@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&family=Rubik:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500;700&display=swap");

.solicitudTitle {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    color: #FFFFFF;
    background: #494949;
    border-radius: 0px 2px 2px 0px;
    margin-top: 1%;
    width: 20%;
    padding: 8px;
    text-align: center;
}

.boxSolicitud{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 0px 5px;
}

.fondoSolicitud{
    background-color: #FFFFFF;
    
}



.solicitudTabColorPedida{
    background: #13A1B7;
    min-width: 30px;
    max-width: 30px;
    min-height: 100%;
    margin-left: 0%;

}
.solicitudTabColorSinDefinir{
    background-color: #C4C4C4;
    min-width: 30px;
    max-width: 30px;
    min-height: 100%;
    margin-left: 0%;

}
.solicitudTabColorAConsiderar{
    background-color: #E54B7A;
    min-width: 30px;
    max-width: 30px;
    min-height: 100%;
    margin-left: 0%;

}
.solicitudTabColorRechazada{
    background-color: #CE2323;
    min-width: 30px;
    max-width: 30px;
    min-height: 100%;
    margin-left: 0%;

}
.solicitudTabColorAceptada{
    background-color: #29A00B;
    min-width: 30px;
    max-width: 30px;
    min-height: 100%;
    margin-left: 0%;

}
.solicitudTabColorCargada{
    background-color: #C4C4C4;
    min-width: 30px;
    max-width: 30px;
    min-height: 100%;
    margin-left: 0%;

}

.botonVerMas{
    background-color: #EB872B;
    margin-left: 5px;

}

.botonAceptaRechaza{
    background-color: #C4C4C4;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;

}

.tituloCard{
    font-size: small;
    background-color: #F5F5F5;

}

.subtituloCard{
    font-size: small;
}



.botonFiltroPedida{
    background-color: #13A1B7;
    color: white;
    height: 66px;
    width: 240px;
    left: 1616px;
    top: 442px;
    border-radius: 5px;
    margin-bottom: 5px;
}
.botonFiltroEnEspera{
    background-color: #704F9B;
    color: white;
    height: 66px;
    width: 240px;
    left: 1616px;
    top: 442px;
    border-radius: 5px;
    margin-bottom: 5px;

}
.botonFiltroAConsideracion{
    background-color: #E54B7A;
    color: white;
    height: 66px;
    width: 240px;
    left: 1616px;
    top: 442px;
    border-radius: 5px;
    margin-bottom: 5px;

}

.botonFiltroAceptada{
    background-color: #F5F5F5;
    height: 66px;
    width: 240px;
    left: 1616px;
    top: 442px;
    border-radius: 5px;
    margin-bottom: 5px;

}
.botonFiltroRechazada{
    background-color: #F5F5F5;
    height: 66px;
    width: 240px;
    left: 1616px;
    top: 442px;
    border-radius: 5px;
    margin-bottom: 5px;

}
.botonFiltroHistorial{
    background-color: #F5F5F5;
    height: 66px;
    width: 240px;
    left: 1616px;
    top: 442px;
    border-radius: 5px;
    margin-bottom: 5px;

} 