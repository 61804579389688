@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&family=Rubik:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500;700&display=swap");

.leaflet-container {
  width: 100%;
  height: 530px;
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #10b981;
}

input:checked ~ .block {
  background-color: #1d884a;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 10; 
}